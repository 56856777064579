import {
  ChangeEvent,
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ComboboxButton, ComboboxInput } from "@headlessui/react";
import joinClassNames from "classnames";
import debounce from "lodash/debounce";

import SearchIcon from "icons/search.svg?react";
import CloseIcon from "icons/close.svg?react";

import BlankInput from "basics/input/components/blank-input";

import classes from "./styles/classes.module.scss";

interface Props {
  placeholder?: string;
  classNames?: Partial<{ input: string }>;
  onChange: (value: string) => void;
}

const Input = forwardRef<HTMLInputElement, Props>(
  ({ placeholder, classNames, onChange }, ref) => {
    const [value, setValue] = useState("");

    const onChangeLazy = useCallback(debounce(onChange, 800), []);

    useEffect(() => () => onChangeLazy.cancel(), []);

    return (
      <BlankInput
        as={ComboboxInput as unknown as "input"}
        className={joinClassNames(classes.wrapper, classNames.input)}
        classNames={{ input: classes.input }}
        before={<SearchIcon className={classes.inputIcons} />}
        after={
          <ComboboxButton className={classes.closeTrigger}>
            <CloseIcon className={classes.inputIcons} />
          </ComboboxButton>
        }
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          setValue(value);
          onChangeLazy(value);
        }}
        value={value}
        placeholder={placeholder}
        innerRef={ref as MutableRefObject<HTMLInputElement>}
        autoFocus
      />
    );
  },
);

export default Input;
